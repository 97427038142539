<template>
  <div class="card card-bordered">
      <div class="card-inner">
          <a-breadcrumb style="padding-bottom: 10px;">
            <router-link :to="{name: 'reports'}"><a-breadcrumb-item>Back To Reports</a-breadcrumb-item></router-link>
            <a-breadcrumb-item>Tickets By Account</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="row">
            <div class="col-3">
                <a-select show-search allowClear option-filter-prop="children" @change="getReport()" v-model="widgetFetcher.accountId" placeholder="Please Select An Account" style="width: 100%">
                    <a-select-option v-for="a in accounts" :key="a.id">
                        {{ a.text }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="col-3">
                <a-select show-search allowClear option-filter-prop="children" @change="getReport()" v-model="widgetFetcher.priorityId" placeholder="Please Select A Priority" style="width: 100%">
                    <a-select-option v-for="p in priorities" :key="p.id">
                        {{ p.text }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="col-6">
                <div class="float-right">
                    <div class="btn-group" style="margin-right: 15px;">
                        <button @click="setMonth('thisMonth')" type="button" class="btn btn-primary btn-sm">This Month</button>
                        <button @click="setMonth('lastMonth')" class="btn btn-dark btn-sm">Last Month</button>
                        <button @click="setMonth('monthBeforeLast')" class="btn btn-dark btn-sm">Month <em style="font-size:10px;" class="icon ni ni-caret-left-fill"></em> Last</button>
                        <button @click="setMonth('thisYear')" class="btn btn-dark btn-sm">This Year</button>
                    </div>
                    <a-date-picker @change="getReport()" v-model="widgetFetcher.start" format="DD/MM/YYYY" placeholder="Start Date" style="margin-right: 10px;"/>
                    <a-date-picker @change="getReport()" v-model="widgetFetcher.end" format="DD/MM/YYYY" placeholder="End Date"/>
                </div>
            </div>
        </div>
        <hr class="preview-hr">
        <div class="row">
            <div class="col-6" v-if="ticketCounts">
                <ticketsbyaccountchart :widgetFetcher="widgetFetcher" :needsRefresh="needsRefresh"/>
            </div>
            <div class="col-6" v-if="ticketCounts">
                <div class="row">
                    <div class="col-6">
                        <div class="card stat">
                            <div class="card-inner">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-title">
                                            <h5 class="title text-center">Total Tickets Completed</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="card-title">
                                                <h4 class="text-center">{{ ticketCounts.completedCount }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card stat">
                            <div class="card-inner">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-title">
                                            <h5 class="title text-center">Total Tickets Outstanding Currently</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="card-title">
                                                <h4 class="text-center">{{ ticketCounts.outstandingCount }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="card stat">
                            <div class="card-inner">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-title">
                                            <h5 class="title text-center">Total Time Spent Working On Tickets (Hours)</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="card-title">
                                                <h4 class="text-center">{{ ticketCounts.timeSpentWorking}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card stat">
                            <div class="card-inner">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-title">
                                            <h5 class="title text-center">Average Time Spent Working Per Ticket (Mins)</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="card-title">
                                                <h4 class="text-center">{{ ticketCounts.avgTimeSpentPerTicket }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="card stat">
                            <div class="card-inner">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-title">
                                            <h5 class="title text-center">Total Ticked Missed SLA</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="card-title">
                                                <h4 class="text-center">{{ ticketCounts.missedSLACount}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="preview-hr">
        <div class="col-12" v-if="tickets">
            <vue-good-table v-if="tickets"
                :columns="columns"
                :rows="tickets"
                styleClass="vgt-table condensed tblhov"
                :search-options="{ enabled: true, placeholder: 'Search Tickets'}"
                :sort-options="{ enabled: true,}"
                @on-cell-click="showTicket"
            >
            <template slot="table-row" slot-scope="props">
                <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-if="props.column.field == 'id'">{{ props.row.id }}</span>
                <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'title'">{{ props.row.title }}</span>
                <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'priority'">{{ props.row.priority }}</span>
                <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'status'">{{ props.row.status }}</span>
                <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'issueType'">{{ props.row.issueType }}</span>
                <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'createdDate'">{{ $moment(props.row.createdDate).format("H:mm DD/MM/YY") }}</span>
                <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'completedDate'">{{ $moment(props.row.completedDate).format("H:mm DD/MM/YY") }}</span>
                <span :class="props.row.slaDueDate > props.row.completedDate ? 'red' : '' " v-else-if="props.column.field == 'slaDueDate'">{{ $moment(props.row.slaDueDate).format("H:mm DD/MM/YY") }}</span>
            </template>
            </vue-good-table>
        </div>
      </div>
    <a-drawer :width="600"
      placement="right"
      :closable="false"
      @close="closeTicket()"
      :visible="showTicketData">
      <ticket :ticket="selectedTicket" />
    </a-drawer>
  </div>
</template>

<script>
import ticket from '@/components/desk/tickets/ticket.vue'
import ticketsbyaccountchart from '@/components/widgets/desk/reports/ticketsbyaccountchart.vue'

export default {
    components: { ticket, ticketsbyaccountchart },
    data() {
        return {
            needsRefresh: null,
            accounts: [],
            priorities: [],
            widgetFetcher: { 
                start: this.$moment.utc().startOf('month'),
                end: this.$moment.utc().endOf('month'),
            },
            selectedMonth: 'thisMonth',
            tickets: [],
            ticketCounts: null,
            showTicketData: false,
            selectedTicket: {},
            columns: [{
                label: 'Ticket No',
                align: 'start',
                sortable: true,
                field: 'id',
            },{
              label: 'Title',
              sortable: false,
              field: 'title',
            }, {
              label: 'Priority',
              field: 'priority'
            }, {
              label: 'Status',
              field: 'status'
            }, {
              label: 'Issue Type',
              field: 'issueType'
            }, {
              label: 'Created Date',
              field: 'createdDate'
            }, {
                label: 'Completed Date',
                field: 'completedDate'
            }, {
              label: 'SLA Due Date',
              field: 'slaDueDate'
            },
            ],
        }
    },
    created() {
        this.getView()
        this.getLists()
    },
    methods: {
        getView (){ this.$http.get('/ViewAccess/ticketByAccount')
        .then(() => { 
        })
        .catch(() => { 
        })},
        showTicket(params) {
          this.selectedTicket = params.row
          this.showTicketData = true
        },
        getLists(){
          this.$http.get('/lists/Get_List/accounts')
          .then((response) => {
              this.accounts = response.data
          })
          .catch(() => {
          this.$message.error('There has been an error')
          })
          this.$http.get('/lists/Get_List/ticketPriorities')
          .then((response) => {
              this.priorities = response.data
          })
          .catch(() => {
          this.$message.error('There has been an error')
          })
        },
        getReport(){
          this.$http.post('/reports/Get_TicketsByAccount/', this.widgetFetcher)
          .then((response) => {
              this.tickets = response.data
          })
          .catch(() => {
            this.$message.error('There has been an error')
          })
          this.$http.post('/reports/Get_TicketsByAccountCounts/', this.widgetFetcher)
          .then((response) => {
              this.ticketCounts = response.data
          })
          .catch(() => {
            this.$message.error('There has been an error')
          })
          this.initiateRefresh()
        },
        closeTicket(){
          this.selectedTicket = null
          this.showTicketData = false
        },
        setMonth(s){
            if (s === 'thisMonth') {
                this.widgetFetcher.start = this.$moment.utc().startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().endOf('month')
            } else if (s === 'lastMonth') {
                this.widgetFetcher.start = this.$moment.utc().subtract(1, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().subtract(1, 'months').endOf('month')
            } else if (s === 'monthBeforeLast') {
                this.widgetFetcher.start = this.$moment.utc().subtract(2, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment.utc().subtract(2, 'months').endOf('month')
            } else if (s === 'thisYear') {
                this.widgetFetcher.start = this.$moment.utc().startOf('year')
            }
            this.getReport()
        },
        initiateRefresh(){
            if (this.needsRefresh === true) {
                this.needsRefresh = false
            } else {
                this.needsRefresh = true
            }
        }
    }
}
</script>

<style scoped>
  .stat{
    height: 165px
  }
  .red{
      color: red;
  }
</style>